<template>
  <Layout ref="layout">
    <div v-if="isSuspend != true" class="head-enterprise-page pl-4">
      <div class="row">
        <div class="col-md-6">
          <div class="box-enterprise-storage mb-4">
            <div class="d-flex flex-column align-items-start justify-content-center">
              <div class="d-flex align-items-center">
                <span class="mr-1 overview-text-enterprise">Total storage</span>
                <img
                  src="@/assets/images/icon/info-grey.svg"
                  style="height: 16px; width: 16px"
                  data-toggle="tooltip"
                  title="Total of the shared storage and enterprise storage."
                  class="cursor-pointer"
                  alt="">
              </div>
              <span class="sum-text-enterprise">{{overviews.total_storage}}</span>
            </div>
            <div class="d-flex align-items-center">
              <img src="@/assets/images/storage-pict-info.svg" style="height: 62px; width: 62px" alt="">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box-enterprise-storage mb-4">
            <div class="d-flex flex-column align-items-start justify-content-center">
              <div class="d-flex align-items-center">
                <span class="mr-1 overview-text-enterprise">Total seat</span>
                <img
                  src="@/assets/images/icon/info-grey.svg"
                  style="height: 16px; width: 16px"
                  data-toggle="tooltip"
                  title="Total seat in your enterprise."
                  class="cursor-pointer"
                  alt="">
              </div>
              <span class="sum-text-enterprise">{{overviews.total_member}}</span>
            </div>
            <div class="d-flex align-items-center">
              <img src="@/assets/images/member-pict-info.svg" style="height: 62px; width: 62px" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSuspend != true" class="list-enterprise-member px-4">
    <!-- <pre>{{overviews}}</pre> -->
      <table class="table-enterprise">
        <thead>
          <th>Name</th>
          <th>Email</th>
          <th>Used Storage</th>
          <th></th>
        </thead>
        <tbody v-if="overviews.member.length > 0">
          <tr v-for="member in overviews.member" :key="member._id">
            <td>
              <div class="d-flex align-items-center">
                <img v-if="member.name === '-'" src="@/assets/images/icon/pending-user.svg" style="height: 32px; width: 32px;" alt="">
                <div v-else class="avatar-xs">
                  <span class="avatar-title-api rounded-circle">{{member.name.charAt(0).toUpperCase()}}</span>
                </div>
                <span class="ml-2 enterprise-member-list-text">
                  {{member.name}}
                </span>
              </div>
            </td>
            <td>
              <span class="enterprise-member-list-text">
                {{member.email}}
              </span>
            </td>
            <td>
              <span class="enterprise-member-list-text">
                {{member.storage}}
              </span>
            </td>
            <td>
              <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-overview">
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/images/icon/btn-more.svg" alt="">
                  </div>
                </template>
                <b-dropdown-item @click="copyToClipboard(member.email)">
                  <div class="d-flex justify-content-start">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                    <span class="mx-3">Copy email</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="overviews.member.length <= 0" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">No enterprise member</span>
        <span class="empty-word-sub-title">The member details you add to the enterprise will be shown here.</span>
      </div>
    </div>
    <SuspendPage v-if="isSuspend == true" />
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import SuspendPage from './suspendEnterprise.vue'
export default {
    data() {
      return {
        // members: [],
        members: [
          {
            name: 'Hari Goodwin',
            email: 'Harigoodwin@gmail.com',
            usedStorage: '750 / 800GB',
            id: 1
          },
          {
            name: '-',
            email: 'Sumayyahprince@gmail.com',
            usedStorage: '50 / 300GB',
            id: 2
          },
          {
            name: 'Carter Passaquindici',
            email: 'Carterpassaquindici@gmail.com',
            usedStorage: '170 / 500GB',
            id: 3
          },
          {
            name: 'Livia Bator',
            email: 'Liviabator@gmail.com',
            usedStorage: '250 / 500GB',
            id: 4
          },
          {
            name: 'Aspen Torff',
            email: 'Aspentorff@gmail.com',
            usedStorage: '300 / 500GB',
            id: 5
          }
        ]
      }
    },
    components: {
      Layout,
      SuspendPage
    },
    mounted(){
      this.$store.dispatch('enterprise/getMyEnterprise')
      this.$store.dispatch('enterprise/getOverviewEnterprise')
      if(this.$store.getters['state/state'] != 'enterprise'){
        this.$router.push({name:'home'}).catch({})
      }
    },
    computed: {
      isSuspend(){
        return this.$store.getters['enterprise/statusSuspend']
      },
      overviews(){
        return this.$store.getters["enterprise/overviewEnterprise"]
      }
    },
    methods: {
      copyToClipboard(email){
        navigator.clipboard.writeText(email)
        this.$toasted.show('Email copied',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      },
    }
}
</script>

<style>

</style>